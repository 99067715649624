export const SESSION_ACTION_BUTTON_MESSAGE_FRAGMENT = `
  fragment sessionActionButtonMessageFragment on Session {
    __typename
    uid
    name
    showLiveChat
    moderators: _moderators {
      uid
      firstName
      lastName
      jobTitle
      employerName
      pictureFileResource {
        schemaCode
        path
      }
    }
  }
`;
